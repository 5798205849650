/**
 * aps模块路由
 */
export const apsRoutes = [
  // 调度计划
  {
    path: '/aps/prodPlan',
    component: () => import('@/views/aps/ProdPlan.vue'),
    meta: { title: "调度计划" },
  },
]