import { BASE_URL, IMG_URL_PRE, CONTRACT_API } from '@/config';
import store from '@/store'
import { postToItemServer,postToContractServer } from './common';

/**
 * 设计超市-分成合作发起签约
 * @param {*} itemId 
 */
export async function goSignPercent(obj){
    return await postToItemServer('/design-market/sign-percent-product',obj);
}
/**
 * 设计超市-工厂购买版权销售商品（老）
 * @param {*} itemId 
 */
export async function goSign(itemId){
    return await postToItemServer('/design-market/buy-price-product', {
        product_no:itemId,
    });
}
// 设计超市-工厂购买版权-单品（新）
export async function buyProduct(product_no){
    return await postToItemServer('/design-market/buy-product', {product_no:product_no,});
}
// 设计超市-工厂购买版权-单品
export async function buySet(set_no){
    return await postToItemServer('/design-market/buy-set', {set_no:set_no,});
}

/**
 * 获取合同模板列表
 * @param {*} compId 
 * @param {*} deptId 
 */
export async function getContractTpls(){
    let tpls = await postToContractServer('/product/get-contract-tpls');
    return tpls;
    // return [
    //     {id:1,title:'模板1',url:'contract_tpl/test3.pdf',params:{amount:{text:'总金额',value:""}}},
    //     {id:2,title:'模板2',url:'contract_tpl/test3.pdf',params:{amount:{text:'违约金',value:""}}},
    //     {id:3,title:'模板3',url:'contract_tpl/test3.pdf',params:{amount:{text:'违约金',value:""}}},
    //     {id:4,title:'模板4',url:'contract_tpl/test3.pdf',params:{amount:{text:'违约金',value:""}}},
    //     {id:5,title:'模板5',url:'contract_tpl/test3.pdf',params:{amount:{text:'违约金',value:""}}}
    // ];
}
// appid:1.版权购买合同，2.打样合同
export async function listContractTpl(appid){
    let tpls = await postToContractServer('/list-tpl',{appid:appid});
    return tpls;
}
/**
 * 查看测试合同预览
 * @param {*} obj
 */
export function testHt(obj){
    // let obj = {
    //     contract_title:"", //合同标题
    //     contract_type:1, // 合同类型，1为版权购买合同，2为打样合同
    //     side_a_id:1, // 甲方
    //     side_b_id:2, // 乙方
    //     content_file: this.newImg, // 合同正文文件路径
    //     custom_cover_page: 0, // 1表示不要自动生成封面页和签字页
    // }
    obj.content_file = obj.content_file.replace(IMG_URL_PRE,'');
    let gotoUrl = CONTRACT_API+'/test-ht?contract_title='+obj.contract_title+"&side_a_id=1&side_b_id=2&content_file="+obj.content_file+"&custom_cover_page="+obj.custom_cover_page+"&token="+store.state.loginUser.token;
    return gotoUrl;
}
/**
 * 查看合同签署地址
 * @param {*} contract_no token 
 */
export function contractView(contract_no){
    let gotoUrl = CONTRACT_API+'/view-ht?contract_no='+contract_no+"&token="+store.state.loginUser.token;
    return gotoUrl;
}
/**
 * 合同下载
 * @param {*} contract_no token 
 */
export function contractDownload(contract_no){
    let gotoUrl = CONTRACT_API+'/download-ht?contract_no='+contract_no+"&token="+store.state.loginUser.token;
    return gotoUrl;
}
/**
 * 获取合同信息
 * @param {*} itemId 
 */
export async function contractInfo(contract_no){
    return await postToContractServer('/get-ht-info', {
        contract_no:contract_no,
    });
}
/**
 * 获取合同签署地址
 * @param {*} contract_no 
 */
export async function contractAddr(contract_no,seal_user_id){
    let redirctUrl = BASE_URL+'/signok';
    return await postToContractServer('/sign-ht', {
        contract_no:contract_no,
        seal_user_id: seal_user_id,
        sign_return_url:redirctUrl,
    });
}
/**
 * 获取自动签约合同地址
 * @param {*} contract_no 
 */
export async function contractAutoAddr(contract_no, seal_user_id) {
    let redirctUrl = BASE_URL + '/signok';
    return await postToContractServer('/grant-autosign', {
        // contract_no: contract_no,
        // seal_user_id: seal_user_id,
        sign_return_url: redirctUrl,
    });
}
/**
 * 获取企业合同相关扩展信息(是否授权自动签约)
 */
export async function getCompExt() {
    return await postToContractServer('/get-comp-ext');
}
/**
 * 自动盖上工厂的章
 */
export async function sealIn(obj) {
    return await postToContractServer('/auto-sign-ht',obj);
}
/**
 * 获取合同文件流
 * @param {*} contract_no token 
 */
export function getContractContent(contract_no) {
    return CONTRACT_API + '/get-ht-content?contract_no=' + contract_no;
}
/* ----------------------------合同管理--------------------------------- */
// 印章持有人列表 (印章管理)
export async function listSealMember(obj){
    return await postToContractServer('/list-seal-member', obj);
}
// 企业全部成员列表 (印章管理)
export async function listCompMember(obj){
    return await postToContractServer('/list-comp-member', obj);
}
// 印章持有人新增授权 (印章管理)
export async function addSealMember(obj){
    return await postToContractServer('/add-seal-member', obj);
}
// 印章持有人取消授权 (印章管理)
export async function delSealMember(obj){
    return await postToContractServer('/del-seal-member', obj);
}
// 印章持有人授权记录列表 (经办合同)
export async function listSealLog(obj){
    return await postToContractServer('/list-seal-log', obj);
}

// 合同列表 (经办合同)
export async function listHt(obj){
    return await postToContractServer('/list-ht', obj);
}