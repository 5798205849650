/**
 * 我的模块路由
 */
export const mineRoutes = [
  // 工厂
  {
    path: "/mine/factory/white",
    meta: { title: "渠道分组" },
    component: () => import("@/views/mine/factory/white/White.vue")
  },
  {
    path: "/mine/factory/white/groupGoods/:id",
    meta: { title: "分组商品列表" },
    component: () => import("@/views/mine/factory/white/GroupGoods.vue")
  },
  // 销售公司
  {
    path: "/mine/sale/white",
    // meta: { title: "渠道白名单" },
    component: () => import("@/views/mine/sale/white/White.vue")
  },
  // {
  //   path: "/mine/sale/groupGoods",
  //   component: () => import("@/views/mine/sale/white/GroupGoods.vue")
  // },
]