/**
 * 销售公司采购管理模块路由
 */
export const salePurchaseRoutes = [
  // { path: 'sjzl', component: () => import('@/views/sale/pur/Sjzl.vue') },
  {
    path: "collect",
    meta: { title: "我的收藏" },
    component: () => import("@/views/sale/pur/collect/Collect.vue")
  },
  {
    path: "cgc",
    meta: { title: "采购车" },
    component: () => import("@/views/sale/pur/Cgc/Cgc.vue")
  },
  {
    path: "cgc/check",
    meta: { title: "核对订单信息" },
    component: () => import("@/views/sale/pur/Cgc/CgcCheck.vue")
  },
  {
    path: "address",
    meta: { title: "收货地址" },
    component: () => import("@/views/sale/pur/address/Address.vue")
  },
  {
    path: "cgdd",
    meta: { title: "采购订单" },
    component: () => import("@/views/sale/pur/cgdd/Cgdd.vue")
  },
  {
    path: "cgdd/detail/:id",
    meta: { title: "订单详情" },
    component: () => import("@/views/sale/pur/cgdd/CgddDetail.vue")
  },
];
