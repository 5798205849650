<template>
    <div class="flex">
        <div style="position:relative;">
            <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                :accept="uploadAccept"
                :before-upload="beforeUpload"
                :http-request="uploadSingle">
                <div v-if="newImg" class="btn">
                    <el-button type="primary" size="mini">重新上传合同正文</el-button>
                    <span style="color:#F66F6A;margin:0 20px">已上传</span>
                    <a @click.stop="goView">效果预览</a>
                </div>
                <div v-else class="btn">
                    <el-button type="primary" size="mini">上传合同正文</el-button>
                </div>
            </el-upload>
            
            <div class="tip">
                <span v-if="type==0">注：正文内容中，不需要包含甲方乙方及签署盖章。甲方乙方及签署盖章、订单信息系统自动生成，必须上传PDF格式，不可上传加密或不可编辑的PDF，上传文件不能超过10M</span>
                <span v-if="type==1">注：必须上传PDF格式，不可上传加密或不可编辑的PDF，上传文件不能超过10M</span>
                <a v-if="type==0" style="margin-left:10px" :href="STATIC_URL_PRE+'/design/contract/refer1.jpg'" target="_bank">样本参考</a>
                <a v-if="type==1" style="margin-left:10px" :href="STATIC_URL_PRE+'/design/contract/refer2.jpg'" target="_bank">样本参考</a>
            </div>
            
        </div>
    </div>
</template>
<script>
    import { IMG_URL_PRE, STATIC_URL_PRE } from '@/config'
    import { uploadFile } from '@/service/common';
    import { testHt } from '@/service/contract';
    export default {
        name: 'UploadPdf',
        model: {
            prop: 'img',
            event: 'change',
        },
        props: ['img','prefix','type','contract_type'],
        data() {
            return {
                imgUrl: IMG_URL_PRE,
                STATIC_URL_PRE,
                uploadAccept: '*/pdf',
                newImg:null,
            }
        },
        watch: {
            img:{
                handler() {
                    if (this.img) {
                        this.newImg = this.imgUrl+this.img;
                    }
                },
                deep: true
            }
        },
        created() {
            if (this.img) {
                this.newImg = this.imgUrl+this.img;
            }
            console.log("img",this.img);
        },
        methods: {
            onChange() {
                this.$emit("change", this.newImg);
            },
            beforeUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 10;
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 10MB!');
                }
                let testmsg = file.name.substring(file.name.lastIndexOf('.')+1)
                const extension = testmsg === 'pdf';
                if(!extension){
                    this.$message.error('上传文件只能是pdf格式！');
                }
                return extension;
            },
            uploadSingle(item) {
                const _loading = this.$loading({
                    lock: true,
                    text: "拼命上传中....请耐心等待",
                });
                uploadFile(item.file, this.prefix, {
                    // onUploadProgress: event => {
                    //   formData.file.percent = (event.loaded / event.total) * 100;
                    //   formData.onProgress(formData.file);
                    // }
                    headers: {"Content-Type": "application/pdf"}
                }).then(url => {
                    this.newImg = url;
                    this.onChange();
                    _loading.close();
                }).catch(err => {
                    _loading.close();
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            goView() {
                let obj = {
                    contract_title:"合同标题",
                    content_file: this.newImg,
                    custom_cover_page: this.type,
                }
                if (this.contract_type == 1) {
                    obj.contract_title = "设计版权购买合同";
                } else if (this.contract_type == 2) {
                    obj.contract_title = "需求定制合同";
                } else if (this.contract_type == 3) {
                    obj.contract_title = "打样合同";
                }
                if (obj.content_file) {
                    window.open(testHt(obj));
                }
            }
        },
    }
</script>
<style scoped>
    .tip {
        font-size: 12px;
        color: #808080;
        margin-top: 10px;
    }
</style>