/**
 * 我的企业模块路由
 */
export const payRoutes = [
  // 付款
  {
    path: "waitPay",
    meta: { title: "支付" },
    component: () => import("@/views/pay/WaitPay.vue")
  },
  // 查看付款记录(列表)
  {
    path: "payList",
    meta: { title: "付款查询列表" },
    component: () => import("@/views/pay/PayList.vue")
  },
  // 查看收款记录(列表)
  {
    path: "payeeList",
    meta: { title: "收款查询列表" },
    component: () => import("@/views/pay/PayeeList.vue")
  },
  // 查看收款信息
  {
    path: "payInfo",
    meta: { title: "查看付款信息" },
    component: () => import("@/views/pay/PayInfo.vue")
  },
  // 查看付款记录
  {
    path: "payLog",
    meta: { title: "查看付款记录" },
    component: () => import("@/views/pay/PayLog.vue")
  },
];
