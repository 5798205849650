/**
 * 政府模块路由
 */
export const govRoutes = [
  // 设计奖补申请申请列表
  {
    path: '/approve/allowance',
    component: () => import('@/views/approve/DesignAllowanceApply.vue')
  },
  // 设计奖补申请申请详情
  {
    path: '/approve/allowance/:id',
    meta: { title: "设计奖补申请" },
    component: () => import('@/views/approve/ApplyDetail.vue')
  },
]