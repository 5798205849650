<template>
  <img :style="style" :src="baseUrl + url" alt="加载中..." />
</template>
<script>
import { IMG_URL_PRE, STATIC_URL_PRE } from "@/config";
export default {
  name: 'NoData',
  props: {
    urlType: {
      type: Number,
      default: 1,
    },
    width: {
      type: Number,
      default: 180,
    },
    url: {
      type: String,
      default: "design/placeholder/meiyougengduo.png",
    },
  },
  computed: {
    baseUrl() {
      // 默认是 STATIC_URL_PRE
      return this.urlType === 1 ? STATIC_URL_PRE : IMG_URL_PRE;
    },
    style() {
      return { width: this.width + "px" };
    },
  },
};
</script>
