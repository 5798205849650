import NoData from "@/components/nodata/NoData";
import DefaultComp from "@/components/nodata/DefaultComp";
import UploadImgs from "@/components/upload/index.vue";
import UploadPdf from '@/components/upload/UploadPdf.vue';
import UploadComp from '@/components/upload/UploadComp.vue';
import UploadIdcard from '@/components/upload/UploadIdcard.vue';
import APS from '@/components/APS.vue';
const components = [
  NoData,
  DefaultComp,
  UploadImgs,
  UploadPdf,
  UploadComp,
  UploadIdcard,
  APS
]

const CustomComponent = {
  install: function (Vue) {
    components.forEach(component => {
      Vue.component(component.name, component)
    })
  }
}

export default CustomComponent