/**
 * 设计公司模块路由
 */
export const desginRoutes = [
  // 设计版权
  {
    path: 'sjbq',
    meta:{title:"设计版权销售"},
    component: () => import('@/views/desgin/sjbq/Sjbq.vue')
  },
  {
    path: 'sjbq/detail/:id',
    meta:{title:"产品详情"},
    component: () => import('@/views/desgin/sjbq/SjbqDetail.vue')
  },
  // 预览
  // {
  //   path: 'detail/view',
  //   meta:{title:"预览详情"},
  //   component: () => import('@/views/desgin/sjbq/ViewDetail.vue')
  // },
  // 设计版权提交新设计
  {
    path: 'sjbq/submit',
    meta:{title:"上传设计详情"},
    component: () => import('@/views/desgin/sjbq/SjbqSubmit.vue')
  },
  // 分成合作
  {
    path: 'fchz',
    meta:{title:"分成合作"},
    component: () => import('@/views/desgin/fchz/Fchz.vue')
  },
  {
    path: 'fchz/detail/:id',
    meta:{title:"产品详情"},
    component: () => import('@/views/desgin/fchz/FchzDetail.vue')
  },
  // 分成合作提交新设计
  {
    path: 'fchz/submit',
    meta:{title:"上传设计详情"},
    component: () => import('@/views/desgin/fchz/FchzSubmit.vue')
  },
  // // 套系管理
  // {
  //   path: 'txgl',
  //   meta:{title:"商品分类"},
  //   component: () => import('@/views/desgin/txgl/Txgl.vue')
  // },
  // // 套系管理下的商品
  // {
  //   path: 'txgl/goodCat',
  //   meta:{title:"商品分类下的商品"},
  //   component: () => import('@/views/desgin/txgl/GoodCat.vue')
  // },
  // 销售合同模板
  // {
  //   path: 'htmb',
  //   component: () => import('@/views/desgin/Htmb.vue')
  // },

  // 设计作品库
  {
    path: 'stored',
    // meta:{title:"设计作品库"},
    component: () => import('@/views/desgin/stored/Stored.vue')
  },
  // 设计作品库-单品-创建作品
  {
    path: 'stored/single/edit',
    meta:{title:"单品作品创建"},
    component: () => import('@/views/desgin/stored/single/SingleEdit.vue')
  },
  // 设计作品库-单品详情
  {
    path: 'stored/single/detail/:id',
    meta:{title:"作品详情"},
    component: () => import('@/views/desgin/stored/single/SingleDetail.vue')
  },
  // 设计作品库-套系作品-创建套系作品
  {
    path: 'stored/series/edit',
    meta:{title:"套系作品创建"},
    component: () => import('@/views/desgin/stored/series/SeriesEdit.vue')
  },
  // 设计作品库-套系作品详情
  {
    path: 'stored/series/detail/:id',
    meta:{title:"套系作品详情"},
    component: () => import('@/views/desgin/stored/series/SeriesDetail.vue')
  },
  // 设计版权订单
  {
    path: 'order/designBq',
    // meta:{title:"设计版权销售"},
    component: () => import('@/views/desgin/order/DesignBq.vue')
  },
  {
    path: 'order/demandCustomize',
    meta: { title: "需求定制" },
    component: () => import('@/views/desgin/order/DemandCustomize.vue')
  },
]