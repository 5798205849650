import axios from 'axios'
import localForage from 'localforage';
import store from '@/store'
import router from '@/router'
import { COMMON_API, MES_API, SERVER_USER_API, SERVER_ITEM_API, CONTRACT_API, SALE_API, ZCY_SHARE_API, TG_SHARE_API, PROP_API, BASEINFO_API, GOV_API, PAY_API, ORDER_API, FILE_API, IMG_URL_PRE, APS_API } from '@/config'
import { Message } from "element-ui";

export async function setLocalStorge(key, val) {
    try {
        await localForage.setItem(key, val);
    } catch (err) {
        console.log(err);
    }
}
export function getLocalStorge(key) {
    return localForage.getItem(key);
}

/**
 * 发送请求到用户服务器
 * @param {*} url 请求的url,例如 /create_captcha
 * @param {*} data 发送的数据,例如 {a:1} 
 */
export async function _postServer(api, url, data, config = {}) {
    console.log(999, data);
    if (store.state.loginUser && store.state.loginUser.token) {
        let loginUser = store.state.loginUser;
        config.headers = Object.assign({}, config.headers, {
            token: loginUser.token,
            user_id: loginUser.user && loginUser.user.id,
            comp_id: loginUser.user && loginUser.user.comp_id
        });
    }
    let resp = await axios.post(api + url, data, config);
    console.log(resp);
    switch (resp.data.errcode) {
        case 0:
            return resp.data.result;
        case 2: {
            // 获取新的token
            let tokenResp = await axios.post(SERVER_USER_API + '/login/refresh-token', {
                token: store.state.loginUser.refresh_token
            });
            if (tokenResp && tokenResp.data.errcode == 0) {
                // 更新用户token
                store.dispatch('updateLogedUser', {
                    token: tokenResp.data.result.token,
                    refresh_token: tokenResp.data.result.refresh_token
                });
                return await _postServer(api, url, data, config);
            } else {
                await store.dispatch('setLogedUser', null);
                router.push('/login');
                throw new Error('登录已过期,请重新登录');
            }
        }
        default:
            // Message.error(resp.data.message);
            // return new Promise((resolve, reject) => {
            //     reject(resp.data.message)
            // })
            throw new Error(resp.data.message);
    }
}
/**
 * 发送get请求到APS服务器
 * @param {*} url 请求的url,例如 /create_captcha
 * @param {*} data 发送的数据,例如 {a:1} 
 */
async function _getApsServer(api, url, params) {
    let resp = await axios.get(api + url, { params });
    switch (resp.data.ErrorCode) {
        case 0:
            return resp.data;
        case 2: {
            // 获取新的token
            let tokenResp = await axios.post(SERVER_USER_API + '/login/refresh-token', {
                token: store.state.loginUser.refresh_token
            });
            if (tokenResp && tokenResp.data.errcode == 0) {
                // 更新用户token
                store.dispatch('updateLogedUser', {
                    token: tokenResp.data.result.token,
                    refresh_token: tokenResp.data.result.refresh_token
                });
                return await _postServer(api, url, params);
            } else {
                await store.dispatch('setLogedUser', null);
                router.push('/login');
                throw new Error('登录已过期,请重新登录');
            }
        }
        default:
            // Message.error(resp.data.message);
            // return new Promise((resolve, reject) => {
            //     reject(resp.data.message)
            // })
            throw new Error(resp.data.Msg);
    }
}
/**
 * 发送post请求到APS服务器
 * @param {*} url 请求的url,例如 /create_captcha
 * @param {*} data 发送的数据,例如 {a:1} 
 */
async function _postApsServer(api, url, params, config = {}) {
    let resp = await axios.post(api + url, params, config);
    switch (resp.data.ErrorCode) {
        case 0:
            return resp.data.Data;
        case 2: {
            // 获取新的token
            let tokenResp = await axios.post(SERVER_USER_API + '/login/refresh-token', {
                token: store.state.loginUser.refresh_token
            });
            if (tokenResp && tokenResp.data.errcode == 0) {
                // 更新用户token
                store.dispatch('updateLogedUser', {
                    token: tokenResp.data.result.token,
                    refresh_token: tokenResp.data.result.refresh_token
                });
                return await _postServer(api, url, params, config);
            } else {
                await store.dispatch('setLogedUser', null);
                router.push('/login');
                throw new Error('登录已过期,请重新登录');
            }
        }
        default:
            // Message.error(resp.data.message);
            // return new Promise((resolve, reject) => {
            //     reject(resp.data.message)
            // })
            throw new Error(resp.data.Msg);
    }
}
/**
 * 发送请求到用户服务器
 * @param {*} url 请求的url,例如 /create_captcha
 * @param {*} data 发送的数据,例如 {a:1} 
 */
async function _getServer(api, url, data, config = {}) {
    console.log(999, data);
    if (store.state.loginUser && store.state.loginUser.token) {
        let loginUser = store.state.loginUser;
        config.headers = Object.assign({}, config.headers, {
            token: loginUser.token,
            user_id: loginUser.user && loginUser.user.id,
            comp_id: loginUser.user && loginUser.user.comp_id
        });
    }
    let resp = await axios.get(api + url, data, config);
    console.log(resp);
    switch (resp.data.errcode) {
        case 0:
            return resp.data.result;
        case 2: {
            // 获取新的token
            let tokenResp = await axios.post(SERVER_USER_API + '/login/refresh-token', {
                token: store.state.loginUser.refresh_token
            });
            if (tokenResp && tokenResp.data.errcode == 0) {
                // 更新用户token
                store.dispatch('updateLogedUser', {
                    token: tokenResp.data.result.token,
                    refresh_token: tokenResp.data.result.refresh_token
                });
                return await _getServer(api, url, data, config);
            } else {
                await store.dispatch('setLogedUser', null);
                router.push('/login');
                return null;
            }
        }
        default:
            throw new Error(resp.data.message);
    }
}
/**
 * 发送请求到mes服务器
 * @param {*} url 请求的url,例如 /create_captcha
 * @param {*} data 发送的数据,例如 {a:1} 
 */
function _postMes(api, url, data, config = {}) {
    if (store.state.loginUser && store.state.loginUser.token) {
        let loginUser = store.state.loginUser;
        config.headers = Object.assign({}, config.headers, {
            Authorization: loginUser.token,
            token: loginUser.token,
            user_id: loginUser.user && loginUser.user.id,
            comp_id: loginUser.user && loginUser.user.comp_id
        });
    }
    return axios.post(api + url, data, config).then(resp => {
        console.log(resp);
        return resp.data;
    });
}
/**
 * 请求通用服务器新
 * @param {String} url 
 * @param {Object} data 
 * @param {Object} config 
 */
function postToCommonServer(url, data, config = {}) {
    return axios.post(COMMON_API + url,data, config)
}
/**
 * 请求mes服务器
 * @param {String} url 
 * @param {Object} data 
 * @param {Object} config 
 */
function postToMesServer(url, data, config = {}) {
    return _postServer(MES_API, url, data, config);
}

/**
 * 请求mes服务器
 * @param {String} url 
 * @param {Object} data 
 * @param {Object} config 
 */
function postToMesServer2(url, data, config = {}) {
    return _postMes(MES_API, url, data, config);
}

/**
 * 请求用户服务器
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToUserServer(url, data, config = {}) {
    return _postServer(SERVER_USER_API, url, data, config);
}
/**
 * 请求交易服务器
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToItemServer(url, data, config = {}) {
    return _postServer(SERVER_ITEM_API, url, data, config);
}
/**
 * 请求合同中心服务器
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToContractServer(url, data, config = {}) {
    return _postServer(CONTRACT_API, url, data, config);
}
/**
 * 请求销售服务器
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToSaleServer(url, data, config = {}) {
    return _postServer(SALE_API, url, data, config);
}
/**
 * 请求销售公司分销服务器（张朝云）
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToZCYShareServer(url, data, config = {}) {
    return _postServer(ZCY_SHARE_API, url, data, config);
}
/**
 * 请求销售公司分销服务器（田果）
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToTGShareServer(url, data, config = {}) {
    return _postServer(TG_SHARE_API, url, data, config);
}
/**
 * 请求销售公司道具服务器
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToPropServer(url, data, config = {}) {
    return _postServer(PROP_API, url, data, config);
}
/**
 * 请求基础资料服务器
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToBaseInfoServer(url, data, config = {}) {
    return _postServer(BASEINFO_API, url, data, config);
}
/**
 * 请求基础资料服务器(用于地址选择)
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function getToBaseInfoServer(url, data, config = {}) {
    return _getServer(BASEINFO_API, url, data, config);
}
/**
 * 请求支付服务器
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToPayServer(url, data, config = {}) {
    return _postServer(PAY_API, url, data, config);
}
/**
 * 请求交易中心服务器
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToOrderServer(url, data, config = {}) {
    return _postServer(ORDER_API, url, data, config);
}
/**
 * 请求政府服务器
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToGovServer(url, data, config = {}) {
    return _postServer(GOV_API, url, data, config);
}
/**
 * 请求文件服务器api
 * @param {*} url 
 * @param {*} data 
 * @param {*} config 
 */
function postToFileServer(url, data, config = {}) {
    return _postServer(FILE_API, url, data, config);
}

/**
 *获得图片上传的url
 * @param {*} filename 文件名
 * @param {*} prefix 前缀
 * @param {*} suffix 后缀
 */
function getUploadUrl(filename, prefix, suffix) {
    return postToFileServer('/front/upload_url', { filename: filename, prefix: prefix, suffix: suffix });
}

/**
 * 上传图片返回全路径
 * @param {*} file 文件对象
 * @param {*} prefix 前缀
 * @param {*} config axios配置
 * @param {*} filename 指定文件名
 */
export async function uploadFile(file, prefix, config = {}, filename) {
    let suffix = file.name.substr(file.name.lastIndexOf('.') + 1);
    let uploadResp = await getUploadUrl(filename, prefix, suffix);
    let resp = await axios.put(uploadResp.url, file, config);
    return uploadResp.filename;
}
/**
 * 删除文件
 * @param {*} filename 
 */
export function delFile(filename) {
    return postToFileServer('/front/del', { files: [filename.replace(IMG_URL_PRE, "")] });
}
/**
 * 获取图形验证码
 */
export function getCaptCha() {
    return postToUserServer('/create_captcha');
}
/**
 * 获取注册手机验证码
 * @param {*} mp 手机号
 */
export function sendSmsCode(mobile) {
    return postToUserServer('/register/get-mobile-code', { mobile: mobile });
}
/**
 * 获取登录手机验证码
 * @param {*} mp 手机号
 */
export function sendLoginSmsCode(mp) {
    return postToUserServer('/login/get-mobile-code', { mobile: mp });
}
/**
 * 获取发送找回密码手机短信验证码
 * @param {*} mp 手机号
 */
export function sendGetSmsCode(mp) {
    return postToUserServer('/find-password/get-mobile-code', { mobile: mp });
}
/**
 * 获取分销商手机短信验证码
 * @param {*} mp 手机号
 */
export function sendMobileCode(mobile, code_type) {
    return postToUserServer('/send-mobile-code', { mobile: mobile, code_type: code_type });
}

function postToApsServer(url, data, config) {
    return _postApsServer(APS_API, url, data, config);
}

function getToApsServer(url, params) {
    return _getApsServer(APS_API, url, params)

}

export {

    postToCommonServer,
    postToUserServer,
    postToItemServer,
    postToContractServer,
    postToSaleServer,
    postToZCYShareServer,
    postToTGShareServer,
    postToPropServer,
    postToBaseInfoServer,
    getToBaseInfoServer,
    postToPayServer,
    postToOrderServer,
    postToGovServer,
    postToMesServer,
    postToMesServer2,
    postToApsServer,
    getToApsServer,

}